import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Button, Form, InputGroup, Row, Col, Container } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import MultiSelect from "react-multi-select-component";
import api from "../services/GenericService";
import Toast from "../services/Toast";
import ProcessingButton from "./ProcessingButton";

const SCREENSHOT_TYPES = [
    { label: 'Amazon', value: 'ss-amazon' },
    { label: 'No Price', value: 'ss-noprice' },
    { label: 'Cropping', value: 'ss-cropping' },
    { label: 'Weird', value: 'ss-weird' },
    { label: 'Captcha', value: 'ss-captcha' },
    { label: 'Popup', value: 'ss-popup' },
    { label: 'Missing', value: 'ss-missing' },
    { label: 'White Page', value: 'ss-whitepage' },
    { label: 'Browser Unsupported', value: 'ss-browser' },
    { label: 'Other', value: 'ss-other' },
];

const TICKET_TITLES = {
    NOT_SHOWING: 'Report Inaccuracy - Missing Website (NS)',
    REJECTED_PRODUCT: 'Report Inaccuracy - Rejected Product (RP)',
    PRODUCT_DISCOVERY: 'Report Inaccuracy - Product Discovery (PD)',
    PRICE_VIOLATION: 'Report Incorrect Violation Crawl'
}

export const TICKET_TYPES = {
    NOT_SHOWING: 'NOT_SHOWING',
    PRODUCT_DISCOVERY: 'PRODUCT_DISCOVERY',
    REJECTED_PRODUCT: 'REJECTED_PRODUCT',
    PRICE_VIOLATION: 'PRICE_VIOLATION'
}

class JIRATicket extends React.Component {

    payload = {};

    constructor(props) {
        super(props);
        this.state = {
            ssType: [],
            ticketType: '',
            notes: this.polishNotes(props.data['notes']) || '',
            expectedPrice: '',
            processing: false
        };

        this.modalForm = this.modalForm.bind(this);
        this.handleChangeTicketType = this.handleChangeTicketType.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createTicket = this.createTicket.bind(this);
        this.fullfillPayload = this.fullfillPayload.bind(this);
        this.uploadable = this.uploadable.bind(this);

        this.fullfillPayload(props);
    }

    polishNotes = (notes) => {
        if (notes === '(missing)') {
            return '';
        }
        try {
            const json = JSON.parse(notes);
            if (json.length > 0) {
                return json.join('; ');
            }
        } catch(e) {
            return notes;
        }
    }

    fullfillPayload = (props) => {
        const collectables = [
            'brand_name',
            'website_name',
            'website_id',
            'product_name',
            'product_id',
            'datetime',
            'offer_price',
            'offer_url',
            'offer_screenshot',
        ];
        collectables.forEach((key) => {
            if (props.data[key]) {
                this.payload[key] = props.data[key];
            }
        });
        this.payload.brand_ids = props.data.brand_id
            ? [props.data.brand_id]
            : [];
        if (props.data.brands) {
            this.payload.brand_name = [];
            props.data.brands.forEach((brand) => {
                this.payload.brand_ids.push(brand.id);
                this.payload.brand_name.push(brand.name);
            });
            this.payload.brand_name = this.payload.brand_name.join(', ');
        }
    }

    handleInputChange = (e) => {
        const state = {};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    handleChangeTicketType = (e) => {
        const type = e.target.value;
        const state = {
            ticketType: type
        };
        if (type === 'SS') {
            state.expectedPrice = '';
        }
        this.setState(state);
    }

    handleTypeChange = (type) => {
        if (type.length > 1) {
            type.splice(0, 1);
        }
        this.setState({ ssType: type });
    }

    modalForm = (type) => {
        if (type !== TICKET_TYPES.PRICE_VIOLATION ) {
            return null;
        }

        const isCT = this.state.ticketType === 'CT';
        const isSS = this.state.ticketType === 'SS';
        return (
            <React.Fragment>
                <Row>
                    <Col sm="3">Ticket Type:</Col>
                    <Col>
                        <Form.Check
                            inline
                            label="Crawler Tweaks"
                            name="group1"
                            type="radio"
                            id="ct"
                            value="CT"
                            checked={isCT}
                            onChange={this.handleChangeTicketType}
                            disabled={this.state.processing}
                        />
                        <Form.Check
                            inline
                            label="Screenshot"
                            name="group1"
                            type="radio"
                            id="ss"
                            value="SS"
                            checked={isSS}
                            onChange={this.handleChangeTicketType}
                            disabled={this.state.processing}
                        />
                    </Col>
                </Row>
                {isCT && (<Row>
                    <Col sm="3">Expected Price:</Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control type="text"
                                disabled={this.state.processing}
                                value={this.state.expectedPrice}
                                name="expectedPrice"
                                onChange={this.handleInputChange} />
                        </InputGroup>
                    </Col>
                </Row>)}
                {isSS && (<Row>
                    <Col sm="3">Screenshot Type:</Col>
                    <Col>
                        <MultiSelect
                            disabled={this.state.processing}
                            hasSelectAll={false}
                            multiple={false}
                            id="ss-options"
                            options={SCREENSHOT_TYPES}
                            value={this.state.ssType}
                            onChange={this.handleTypeChange}
                            labelledBy="Select type"
                        />
                    </Col>
                </Row>)}
            </React.Fragment >
        );
    }

    uploadable = () => {
        if (this.props.type !== TICKET_TYPES.PRICE_VIOLATION) {
            return true;
        }
        if (this.state.ticketType.trim().length === 0) {
            return false;
        }
        if (this.state.ticketType === 'CT') {
            return this.state.expectedPrice.length > 0;
        }
        return this.state.ssType.length > 0;
    }

    createTicket = () => {
        this.setState({ processing: true });
        const payload = Object.assign({}, this.payload);
        payload.notes = this.state.notes;
        switch (this.props.type) {
            case TICKET_TYPES.PRICE_VIOLATION:
                payload.type = this.state.ticketType;
                if (this.state.ticketType === 'CT') {
                    payload.expected_result = this.state.expectedPrice;
                } else {
                    payload.notes = 'Violation SS URL = ' + payload.offer_screenshot + '\n' +
                        'SS Type = ' + this.state.ssType[0].value + '\n\n' +
                        this.state.notes;
                }
                break;
            case TICKET_TYPES.NOT_SHOWING:
                payload.type = 'NS';
                break;
            case TICKET_TYPES.PRODUCT_DISCOVERY:
                payload.type = 'PD';
                break;
            case TICKET_TYPES.REJECTED_PRODUCT:
                payload.type = 'RP';
                break;
            default:
                payload.type = 'Fix';
        }
        payload.description = payload.type
            + ' [' + payload.brand_name + '] '
            + payload.website_name;

        ['website_name', 'brand_name', 'product_name'].forEach((key) => {
            delete payload[key];
        });

        api.cbsHelper(payload).then((data) => {
            const url = 'https://juststicky.atlassian.net/browse/' + data.ticket;
            this.setState({ processing: false });
            if (data.success) {
                Toast.ok(<span>
                    Ticket created successfully:
                    <a href={url} target="_blank" rel="noreferrer"> {data.ticket}</a>
                </span>,
                    5000);
                this.props.proceed(true);
            } else {
                if (data.ticket) {
                    Toast.info(<span>
                        This ticket duplicates:
                        <a href={url} target="_blank" rel="noreferrer"> {data.ticket}</a>
                    </span>,
                        5000);
                } else {
                    Toast.error('Failed to create ticket.');
                }
            }
        });
    }

    render() {
        const {
            show,
            proceed,
            type,
        } = this.props;
        const modalTitle = TICKET_TITLES[type];
        const { brand_name, product_name, website_name } = this.payload;

        return (
            <Modal
                show={show}
                onHide={() => this.state.processing ? null : proceed(false)}
                backdrop="static"
                keyboard={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon
                            icon={faBug}
                            className='subMenuIcon'
                        />{modalTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {brand_name && <Row>
                            <Col sm="3">Brand:</Col>
                            <Col className="bold">{brand_name}</Col>
                        </Row>}
                        {website_name && <Row>
                            <Col sm="3">Website:</Col>
                            <Col className="bold">{website_name}</Col>
                        </Row>}
                        {product_name && <Row>
                            <Col sm="3">Product:</Col>
                            <Col className="bold">{product_name}</Col>
                        </Row>}
                        {this.modalForm(type)}
                        <Row>
                            <Col sm="3">Notes:</Col>
                            <Col>
                                <Form.Control as="textarea"
                                    rows={5}
                                    name="notes"
                                    value={this.state.notes}
                                    disabled={this.state.processing}
                                    onChange={this.handleInputChange} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => proceed(false)}
                        disabled={this.state.processing}
                        data-testid="confirm-btn-cancel"
                    >
                        Cancel
                    </Button>
                    <ProcessingButton
                        variant="primary"
                        onClick={this.createTicket}
                        disabled={!this.uploadable()}
                        data-testid="confirm-btn-ok"
                        processing={this.state.processing}
                    >
                        Create Ticket
                    </ProcessingButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const confirm = (
    type = TICKET_TYPES.PRICE_VIOLATION,
    data = [],
) => {
    return createConfirmation(confirmable(JIRATicket))({
        type,
        data,
    });
}
