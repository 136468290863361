import React from "react";

import Brands from "../../../services/BrandService";
import {
    Form,
    Button,
    Carousel,
    Container,
    Col,
    Row,
    Breadcrumb,
    FloatingLabel,
} from "react-bootstrap";
import confirm from "../../../components/Confirm";
import ProgressBar from "../../../components/ProgressBar";
import Toast from "../../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faCheck, faDownload, faExternalLinkAlt, faServer, faSpider, faTimes, faTools } from "@fortawesome/free-solid-svg-icons";
import DelayedInput from "../../../components/DelayedInput";
import Utils from "../../../services/Utils";
import CSV from "../../../services/CSV";
import { TICKET_TYPES, confirm as ticket } from "../../../components/JIRATicket";
import WebsiteService from "../../../services/WebsiteService";
import { Link, withRouter } from "react-router-dom";
import FixMissingWebsites from "../../../components/FixMissingWebsites";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import BrandSearch from "../../../components/BrandSearch";
import Currencies from "../../../components/Currencies";
import Countries from "../../../components/Countries";
import ProcessingButton from "../../../components/ProcessingButton";
import MyCarousel from "../../../components/MyCarousel";
import MyTable from "../../../components/MyTable";
import { createRef } from "react";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            brand: null,
            isWebsitesLoading: false,
            options: [],
            websites: "",
            processingList: [],
            processingIndex: 0,
            isProcessing: true,
            showModal: null,
            countries: [],
        };

        this.tableRef = createRef();

        this.TypeAhead = this.TypeAhead.bind(this);
        this.processNext = this.processNext.bind(this);
        this.handleLoadWebsites = this.handleLoadWebsites.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeList = this.onChangeList.bind(this);
        this.startProcessing = this.startProcessing.bind(this);
        this.finishProcessing = this.finishProcessing.bind(this);
        this.onSlide = this.onSlide.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleFixResult = this.handleFixResult.bind(this);
        this.getBrandID = this.getBrandID.bind(this);
        this.handleWebsiteProfile = this.handleWebsiteProfile.bind(this);
        this.handleCommentsChange = this.handleCommentsChange.bind(this);

        //dropdown menus
        this.handleCreateJIRA = this.handleCreateJIRA.bind(this);
        this.handleShowMerchant = this.handleShowMerchant.bind(this);
        this.handleSAW = this.handleSAW.bind(this);

        if (window.location.hash.match(/results/)) {
            this.props.history.push('/diags/mw');
        }
    }

    handleChange(row) {
        this.start = null;
        this.tableRef.current?.populate([]);

        this.setState({
            brand: row[0],
        });

    };

    onSlide(key) {
        if (key === 0) {
            this.setState({
                processingList: [],
                processingIndex: 0,
                isProcessing: false,
                canceled: true,
            });
        }
    }

    handleLoadWebsites() {
        this.setState({
            isWebsitesLoading: true
        });
        Brands.getSellingWebsites(this.state.brand.id).then((result) => {
            this.setState({ isWebsitesLoading: false });
            let list = [];
            result.forEach((item) => {
                list.push(item.name.toLowerCase());
            });
            list.sort();
            this.setState({
                websites: this.state.websites + '\n' + list.join('\n')
            });
        });
    }

    startProcessing() {
        let unique = {},
            current = this.state.websites.split('\n');

        this.items = [];
        this.map = {};
        this.fixable = [];
        this.processingIndex = 0;
        this.tableRef.current?.populate([]);
        this.countries = {};

        current.forEach((line) => {
            let parts = line.split(',');
            parts.forEach((domain) => {
                let polished = domain.trim().toLowerCase();
                if (polished.split('.').length === 1) {
                    return;
                }
                unique[polished] = null;
            });
        });

        this.setState({
            processingList: Object.keys(unique),
            processingIndex: 0,
            isProcessing: true,
            canceled: false,
        }, () => {
            this.props.history.push("#results");
            this.processNext();
        })
    }

    async finishProcessing() {
        this.countries = {};
        if (!this.fixable.length) {
            return;
        }
        let plural = this.fixable.length > 1;
        if (
            await confirm("Shall we?", "Yes, let's do it!", "Not now", {
                message: this.fixable.length + " " + (plural ? "items seem " : "item seems ") + " to be easily fixed. Would you like fix " + (plural ? "them" : "it") + " now?",
                enableEscape: false,
            })
        ) {
            this.setState({
                showModal: this.fixable
            })
        }
    }

    processNext() {

        if (this.start === null) {
            this.start = Date.now();
        }

        let next = this.state.processingIndex;
        if (next >= this.state.processingList.length) {
            this.setState({
                isProcessing: false,
                processingIndex: 0,
            });

            if (!this.state.canceled) {
                Toast.ok("Finished loading data after " + Utils.Seconds2Time((Date.now() - this.start) / 1000));
            }

            this.finishProcessing();

        } else {

            let website = this.state.processingList[next],
                brand_id = this.state.brand.id;

            Brands.troubleshoot(brand_id, [website]).then((result) => {
                let shouldUpdateCountries = false;
                if (result === false) {
                    Toast.error("Something went wrong while request data from website '" + website + "'")
                    return;
                }

                result.forEach((row) => {
                    const country_id = row.country_id ?? null;
                    if (country_id && typeof this.countries[country_id] === "undefined") {
                        const country = Countries.GetByID(country_id);
                        if (country) {
                            shouldUpdateCountries = true;
                            this.countries[country_id] = {
                                value: country_id,
                                label: country.label,
                            };
                        }
                    }
                    row.unique = Date.now();
                    if (row.errors === null) {
                        row.errors = [];
                    }
                    if (row.currencies === null) {
                        row.currencies = [row.currency_id];
                    }
                    if (this.tableRef.current === null) {
                        return;
                    }
                    this.tableRef.current.addRow(row);
                    if (!row.status) {
                        let error = row.errors.join(" ");
                        if (error.match(/(have NO category|no crawler for)/)) {
                            let nRow = Object.assign({}, row);
                            nRow.checked = true;
                            nRow.type = error.match(/have NO category/) ? "categories" : "create";
                            this.fixable.push(nRow);
                        }
                    }
                })

                this.setState({
                    processingIndex: next + 1,
                    ...shouldUpdateCountries
                        ? { countries: Object.values(this.countries) }
                        : {},
                }, this.processNext)

            });
        }
    }

    onChangeList(evt) {
        this.setState({ websites: evt.target.value });
    }


    handleSAW(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            mode = obj.target.getAttribute("saw");

        WebsiteService.requestProductDiscovery(
            obj.row.id,
            this.state.brand.id,
            mode
        ).then((result) => {
            if (result) {
                Toast.ok("SAW execution requested successfully!");
            } else {
                Toast.error("Something went wrong while requesting SAW execution");
            }
        });
    }

    handleShowMerchant(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        WebsiteService.merchant(obj.row.id).then((result) => {
            if (result === false || result.length === 0) {
                Toast.error("Something went wrong while retrieving " + obj.row.name + "]'s merchant info");
                return;
            }

            (async () => {
                const url = `https://platform.trackstreet.com/merchants/${result[0].id}`;
                if (
                    await confirm("Open URL", "Yes", "No", {
                        message: `Would you like to open ${url} in a new tab?`,
                    })
                ) {
                    window.open(url, "_blank", "noopener,noreferrer");
                }
            })();
        });
    }

    async handleCreateJIRA(evt) {
        const obj = this.tableRef.current.getRowFromEvent(evt);
        const type = evt.syntheticEvent.target.innerHTML;

        await ticket(
            type === 'NS' ? TICKET_TYPES.NOT_SHOWING : TICKET_TYPES.PRODUCT_DISCOVERY,
            {
                notes: Array.isArray(obj.row.errors)
                    ? `Possible reason: ${obj.row.errors.join(" ")}`
                    : '',
                website_id: obj.row.id,
                website_name: obj.row.name,
                brand_id: this.state.brand.id,
                brand_name: this.state.brand.name,
            }
        );
    }

    handleDownload() {
        let csv = new CSV(),
            items = this.tableRef.current.data();

        csv.addColumn(
            "Website",
            "Status",
            "Notice",
            "Comments",
            "Bucket",
            "Type",
            "SAW",
            "SAW Last Run",
            "Selling Country",
            "Currency",
            "Supported currencies",
            "Website profile"
        );

        items.forEach((item) => {
            let currencies = [];
            item.currencies.forEach((currency_id) => {
                currencies.push(Currencies.CodeByID(currency_id));
            });

            csv.addRow(
                item.name,
                item.status ? "OK" : "Not OK",
                this.handleNoticeForExport(item),
                item.comments,
                item.bucket,
                item.type.toUpperCase(),
                item.saw.toUpperCase(),
                item.saw_last,
                Countries.GetByID(item.country_id)?.label ?? '',
                Currencies.CodeByID(item.currency_id),
                currencies.join(","),
                (item.id ? "https://tools.trackstreet.com/website/" + item.id : ""),
            );
        });
        csv.Download("mw-brand" + this.state.brand.id + ".csv");
    }

    handleNoticeForExport(item) {
        if (typeof item.errors !== "undefined") {
            return item.errors.join(" ")
        }

        if (typeof item.merchant !== "undefined") {
            return item.merchant.id === 0
                ? (item.message ?? "") + " - https://tools.trackstreet.com/website/" + item.id + "#merchants"
                : (item.message ?? "") + " - https://tools.trackstreet.com/merchant/" + item.merchant.id
        }

        return ""
    }

    handleWebsiteProfile(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        window.open("/website/" + obj.row.id, "_blank", "noopener,noreferrer");
    }

    handleCommentsChange(field, value, target) {
        let obj = this.tableRef.current.getRowFromEvent(target);
        WebsiteService.update({
            id: obj.row.id,
            comments: value
        }).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while saving changes");
            } else {
                Toast.ok("Comments updated successfully");
                obj.row.comments = value;
                this.tableRef.current.updateRow(obj.row);
            }
        });
    }


    handleFixResult(result) {
        this.setState({ showModal: null });
        if (result) {
            Toast.ok("Fixes have been applied successfully. Mind that their respective rows might be outdated now.", 5000);
        }
    }


    getBrandID() {
        if (typeof this.state.brand !== "undefined" && this.state.brand !== null && typeof this.state.brand.id !== "undefined") {
            return this.state.brand.id;
        }
        return 0;
    }

    TypeAhead = () => {
        return (
            <React.Fragment>
                <Row>
                    <BrandSearch onChange={this.handleChange} />
                </Row>
                <Row>
                    <FloatingLabel label="Websites">
                        <Form.Control
                            as="textarea"
                            placeholder="List of Websites"
                            value={this.state.websites}
                            onChange={this.onChangeList}
                            style={{ height: '350px' }}
                        />
                    </FloatingLabel>
                </Row>
                <Row className="d-flex align-items-center justify-content-center text-center">
                    <div>

                        <ProcessingButton
                            size="sm"
                            variant="secondary"
                            processing={this.state.isWebsitesLoading}
                            processingLabel="Loading websites ..."
                            label="Load websites"
                            onClick={this.handleLoadWebsites}
                            disabled={this.state.brand === null}
                            minWidth="150px"
                        />
                        <Button size="sm"
                            disabled={this.getBrandID() === 0 || this.state.websites.trim().length === 0}
                            style={{
                                width: "150px"
                            }} variant="primary" onClick={this.startProcessing}>Troubleshoot</Button>
                    </div>
                </Row>
            </React.Fragment>
        );
    };

    render() {
        const key = window.location.hash.match(/results/) ? 1 : 0,
            ProcessingStatus = () => {
                if (
                    !this.state.isProcessing ||
                    this.state.processingList.length === 0 || this.state.processingIndex >= this.state.processingList.length
                ) {
                    return null;
                }
                let inProcess =
                    this.state.processingList[this.state.processingIndex];
                if (typeof inProcess == "undefined") {
                    return null;
                }
                return (
                    <Row>
                        <Col sm={6}>
                            ({this.state.processingIndex + 1}/
                            {this.state.processingList.length}) Checking{" "}
                            {inProcess}
                        </Col>
                        <Col sm={6}>
                            <ProgressBar
                                bgcolor="#1f4503"
                                completed={
                                    (this.state.processingIndex * 100) /
                                    this.state.processingList.length
                                }
                            />
                        </Col>
                    </Row>
                );
            }

        return (
            <Container className="pt-4" >
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Diagnostics</Breadcrumb.Item>
                    <Breadcrumb.Item
                        active={key === 0}
                        onClick={() => { this.props.history.push("/diags/mw") }}
                    >
                        Missing Websites
                    </Breadcrumb.Item>
                    {key === 1 && (
                        <Breadcrumb.Item active>Results</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <FixMissingWebsites brandId={this.getBrandID()} show={this.state.showModal !== null} issues={this.state.showModal === null ? [] : this.state.showModal} onClose={this.handleFixResult} />
                <MyCarousel activeIndex={key} onSlide={this.onSlide}>
                    <Carousel.Item>
                        <this.TypeAhead />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ProcessingStatus />
                        <MyTable
                            name="diagnosis-missing-websites"
                            dynamic={false}
                            ref={this.tableRef}
                            sortBy="status"
                            sortOrder="asc"
                            headers={
                                [
                                    {
                                        field: "name",
                                        label: "Website",
                                        width: "250px",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "50px",
                                        field: "status",
                                        label: "OK",
                                        sortable: true,
                                        hint: "Crawler's functional state",
                                    },
                                    {
                                        field: "notice",
                                        label: "Notice",
                                    },
                                    {
                                        field: "comments",
                                        label: "Comments",
                                        searchable: true,
                                        sortable: true
                                    },
                                    {
                                        width: "75px",
                                        field: "bucket",
                                        label: "Bucket",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        field: "country_id",
                                        label: "Selling Country",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: this.state.countries,
                                    },
                                    {
                                        width: "50px",
                                        field: "saw",
                                        label: "SAW",
                                        hint: "Semi-Automatic Website",
                                        sortable: true,
                                    },
                                    {
                                        width: "50px",
                                        field: "saw_last",
                                        label: "PD",
                                        hint: "Last Product Discovery run",
                                        sortable: true,
                                    },
                                    {
                                        width: "50px",
                                        field: "currency_id",
                                        label: "$",
                                        hint: "Default currency",
                                        sortable: true,
                                    },
                                    {
                                        width: "75px",
                                        field: "currencies",
                                        label: "$$",
                                        hint: "Supported currencies",
                                        sortable: true,
                                    },
                                    {
                                        field: "control",
                                        width: "1px",
                                        content: <Button disabled={this.state.isProcessing} onClick={this.handleDownload} size="sm" variant="outline-primary" style={{
                                            minWidth: "40px",
                                            marginLeft: "0px",
                                            marginRight: "0px"
                                        }}>
                                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                        </Button>
                                    }
                                ]}
                            renderRows={{
                                className: (row) => !row.status ? "blocked" : "success"
                            }}
                            renderColumns={{
                                name: (row) => <Link className="link" to={`/website/${row.id}`} target="_blank">(#{row.id}) {row.name}</Link>,
                                status: {
                                    format: (row) => row.status ?
                                        <FontAwesomeIcon
                                            style={{
                                                color: "#1f4503",
                                            }}
                                            icon={faCheck}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            style={{ color: "red" }}
                                            icon={faTimes}
                                        />,
                                    className: "text-center"
                                },
                                notice: (row) => {
                                    let errors = row.status ? "" : row.errors.join('\n');
                                    if (!row.status && typeof row.similar !== "undefined") {
                                        errors += "\nSimilar website" + (row.similar.length > 1 ? "s" : "") + ": ";
                                        row.similar.forEach((w) => {
                                            errors += w.website + "; ";
                                        });
                                    }
                                    if (errors.length) {
                                        return <Form.Control
                                            type="text"
                                            as="textarea"
                                            value={errors}
                                            style={{
                                                fontSize: "small",
                                                height: "100px",
                                                backgroundColor: "transparent",
                                            }}
                                            readOnly={true}
                                        />
                                    }
                                    if (typeof row.merchant !== "undefined") {
                                        if (typeof row.message === 'string' && row.message.length > 0) {
                                            return row.message
                                        }

                                        if (row.merchant.id === 0) {
                                            return <Link className="link" to={`/website/${row.id}#merchants`} target="_blank">{row.merchant.name}</Link>
                                        }
                                        return <Link className="link" to={`/merchant/${row.merchant.id}`} target="_blank">{row.merchant.name}</Link>
                                    }
                                    return null
                                },
                                comments: (row) => {
                                    let comments = typeof row.comments === "string" ? row.comments : "",
                                        errors = row.status ? "" : row.errors.join('\n');
                                    if (errors.match(/has explicitly blacklisted/)) {
                                        comments += (comments.length > 0 ? ";\n" : "") + "( In blacklist )"
                                    } else if (errors.match(/has white-listed/)) {
                                        comments += (comments.length > 0 ? ";\n" : "") + "( Not in whitelist )"
                                    }
                                    if (! row.id) {
                                        return null;
                                    }
                                    return <DelayedInput
                                        key={`comments-${row.id}`}
                                        as="textarea"
                                        style={{
                                            fontSize: "small",
                                            height: "100px",
                                            backgroundColor: "transparent",
                                        }}
                                        onChange={this.handleCommentsChange}
                                        value={comments}
                                        minLength={0}
                                        onHitEnterOnly={false}
                                    />
                                },
                                saw: {
                                    format: (row) => row.saw.length === 0 ? null : <FontAwesomeIcon
                                        style={{
                                            color: "#1f4503",
                                        }}
                                        icon={faCheck}
                                    />,
                                    className: "text-center"
                                },
                                saw_last: (row) => Utils.SimpleDay(row.saw_last),
                                country_id: (row) => Countries.GetByID(row.country_id)?.label ?? '',
                                currency_id: {
                                    format: (row) => <div
                                        className={`currency-flag currency-flag-${Currencies.CodeByID(row.currency_id).toLowerCase()}`}
                                    ></div>,
                                    className: "text-center"
                                },
                                currencies: {
                                    format: (row) => row.currencies.map((currency_id) =>
                                        <div
                                            key={`currencies-${row.unique}-${currency_id}`} className={`currency-flag currency-flag-${Currencies.CodeByID(currency_id).toLowerCase()}`}
                                        ></div>
                                    ),
                                    className: "text-center"
                                },
                                control: (row) => {

                                    let SAW = () => {
                                        if (typeof row.saw === "string" && row.saw.length > 0) {
                                            if (row.saw.indexOf('+') !== -1) {
                                                return (
                                                    <SubMenu label={<React.Fragment>
                                                        <FontAwesomeIcon
                                                            icon={faSpider}
                                                            style={{
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        Dispatch SAW run
                                                    </React.Fragment>}>
                                                        <MenuItem saw="upc" onClick={this.handleSAW}>UPC</MenuItem>
                                                        <MenuItem saw="bdo" onClick={this.handleSAW}>BDO</MenuItem>
                                                    </SubMenu>
                                                )
                                            } else {
                                                return (
                                                    <MenuItem saw={row.saw} onClick={this.handleSAW}>
                                                        <FontAwesomeIcon
                                                            icon={faSpider}
                                                            style={{
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        Dispatch SAW run
                                                    </MenuItem>
                                                )
                                            }
                                        }
                                        return null;
                                    }

                                    return <Menu
                                        direction="left"
                                        style={{
                                            fontSize: "medium"
                                        }}
                                        menuButton={
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                style={{
                                                    minWidth: "35px",
                                                    marginLeft: "0px",
                                                    marginRight: "0px"
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTools} />
                                            </Button>}>
                                        <MenuItem onClick={this.handleWebsiteProfile}>
                                            <FontAwesomeIcon
                                                icon={faServer}
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Website Profile
                                        </MenuItem>
                                        {SAW()}
                                        <MenuItem onClick={this.handleShowMerchant}>
                                            <FontAwesomeIcon
                                                icon={faExternalLinkAlt}
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Show Merchant Profile</MenuItem>
                                        <SubMenu
                                            label={
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faBug}
                                                        className='subMenuIcon'
                                                    /> Create JIRA ticket
                                                </span>}
                                            direction={`right`}
                                            offsetY={-8}>
                                            <MenuItem onClick={this.handleCreateJIRA}>NS</MenuItem>
                                            <MenuItem onClick={this.handleCreateJIRA}>PD</MenuItem>
                                        </SubMenu>
                                    </Menu >
                                }
                            }}
                        />
                    </Carousel.Item>
                </MyCarousel>
            </Container >
        );
    }
}
export default withRouter(Index);
